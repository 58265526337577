




























import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import PersonDrawerDetail from '@/components/dashboard/PersonDetail/detail.vue'

@Component({
  name: 'index',
  components: {
    PersonDrawerDetail
  }
})
export default class PersonDetail extends Vue {
  @Prop({ default: false }) private showDetail: boolean
  @Prop({ default: '' }) private person: string
  @Prop() private screenCon: string
  private allDrawer: any[] = []
  private drawerIndex = 1
  private isFullScreen = true
  @Watch('drawerIndex')
  watchDrawerIndex(val: number) {
    if (val === 0) {
      this.$emit('close')
    }
  }
  deactivated() {
    this.$emit('close')
  }
  mounted() {
    const obj = {
      showDetail: true,
      person: this.person,
      isHideMap: false
    }
    this.allDrawer.push(obj)
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
  private handelClose() {
    this.drawerIndex--
    this.allDrawer.pop()
    if (this.allDrawer.length > 0) {
      this.allDrawer[this.allDrawer.length - 1].isHideMap = false
    }
  }
  private toPersonDeatil(val: any) {
    if (this.allDrawer.length > 0) {
      this.allDrawer[this.allDrawer.length - 1].isHideMap = true
    }
    const obj = {
      showDetail: true,
      person: val,
      isHideMap: false
    }
    this.drawerIndex++
    this.allDrawer.push(obj)
  }
  private handleResize() {
    const width = document.documentElement.clientWidth
    if (width < 1366) {
      this.isFullScreen = false
    } else {
      this.isFullScreen = true
    }
  }
}
