







import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { variables } from '@/libs/theme'
import { getWidth } from '@/utils/dom'
let dgmap: AnyObj = {}

@Component
export default class FengMap extends Vue {
  @Prop({ required: true }) value: string
  view = true
  createpoly = 0
  index = 0
  levels: any[] = []
  s3Endpoint = ''

  destroyed() {
    clearInterval(this.createpoly)
  }

  @Watch('value', { immediate: true })
  watchValue() {
    this.getMapInfo()
  }

  viewClick() {
    this.view = !this.view
    if (Object.keys(dgmap).length > 0) {
      dgmap.setViewMode({
        mode: this.view ? fengmap.FMViewMode.MODE_3D : fengmap.FMViewMode.MODE_2D
      })
    }
  }

  async getMapInfo() {
    const { config } = this.$store.state
    if (config && config.children && config.map) {
      this.children = [...config.children, config.root]
      this.s3Endpoint = config.s3Endpoint
      this.init(config.map)
    }
  }

  init(info: AnyObj) {
    let options = {}
    if (info.target !== undefined && info.target !== null) {
      const target = info.target
      if (target.indexOf(',') < 0) {
        options = Object.assign(options, { rotation: Number(info.target) })
      } else {
        options = Object.assign(options, { rotation: Number(target.split(',')[0]) })
        options = Object.assign(options, { tiltAngle: Number(target.split(',')[1]) })
      }
    }
    if (info.position !== undefined && info.position !== null) {
      const pos = info.position
      const center = {
        x: Number(pos.split(',')[0]),
        y: Number(pos.split(',')[1])
      }
      options = Object.assign(options, { center })
    }
    if (info.scale !== undefined && info.scale !== null) {
      setTimeout(() => {
        const mapBox = document.getElementsByClassName('map-box')[0] as HTMLElement
        const contentWidth = getWidth(mapBox)
        const scale = (1920 / contentWidth) * info.scale
        options = Object.assign(options, { mapZoom: scale })
      })
    }
    this.$nextTick(() => {
      const tenantId = this.$store.state.tenantId
      const defaultOpt = {
        container: document.getElementById('dgmap'),
        mapURL: `${this.s3Endpoint}/maps/${tenantId}/`,
        themeURL: `${this.s3Endpoint}/maps/${tenantId}/${info.id}/`,
        themeID: info.id,
        appName: info.app, // 开发者申请应用名称
        key: info.key, // 开发者申请应用下web服务的key
        mapID: info.id,
        viewMode: fengmap.FMViewMode.MODE_3D,
        backgroundAlpha: 0.01
      }
      const opt = Object.assign(defaultOpt, options)
      dgmap = new fengmap.FMMap(opt) // 初始化地图 地图的基础配置
      dgmap.on('loaded', async () => {
        this.levels = dgmap.getLevels()
        dgmap.maxFps = 5
        this.getArea()
        window.maps.push(dgmap)
      })
    })
  }

  getArea() {
    if (this.value === null) {
      dgmap.setLevel({ level: this.levels[0] })
      dgmap.setVisibleLevels([this.levels[0]])
      this.$emit('loadComplete', this.levels[0], dgmap)
    } else {
      this.children.forEach(async (obj: AnyObj) => {
        if (obj.id === this.value) {
          let data
          if (obj.mapLabel && obj.mapLabel.indexOf('mark') > -1) {
            const area = this.formatMapLabel(obj)
            if (area.level && this.levels.includes(area.level)) {
              const x = area.center.x
              const y = area.center.y
              const bound = dgmap.getBound()
              const { max, min } = bound
              if (x > min.x && x < max.x && y > min.y && y < max.y) {
                data = area
              } else {
                this.$emit('loadComplete')
                return false
              }
            } else {
              this.$emit('loadComplete')
              return false
            }
          } else {
            this.$emit('loadComplete')
            return false
          }
          dgmap.setLevel({ level: data.level })
          dgmap.setVisibleLevels([data.level])
          this.$emit('loadComplete', data.level, dgmap)
          this.addMaker(data)
          this.PolygonMaker(data)
        }
      })
    }
  }

  addMaker(data: AnyObj) {
    const floor = dgmap.getFloor(data.level)
    if (!floor) return
    const textMarker = new fengmap.FMTextMarker({
      x: data.center.x,
      y: data.center.y,
      text: data.name,
      // 填充色
      fillColor: '0,0,0',
      // 字体大小
      fontsize: 13,
      height: 2,
      // 边框色
      strokeColor: '255,255,255'
    })
    textMarker.addTo(floor)
    const layer = floor.getLayers()
    layer.forEach((item: any) => {
      if (item.type === fengmap.FMType.LABEL_LAYER) {
        item.visible = false
      }
    })
  }
  formatMapLabel(item: AnyObj) {
    if (item.mapLabel !== null && item.mapLabel.indexOf('mark') > -1) {
      const valueList = item.mapLabel.split('mark:')[1].split(';')
      item.level = Number(valueList[2])
      const center = valueList[1]
      const coord: AnyObj = {
        x: '',
        y: ''
      }
      coord.x = Number(center.slice(1, center.indexOf(',')))
      coord.y = Number(center.slice(center.indexOf(',') + 1, center.length - 1))
      item.center = coord

      const points: string[] = valueList[0].split(',')
      const list: number[] = []
      points.forEach((obj, index) => {
        if (index % 2 === 0) {
          list.push(Number(obj.split('(')[1]))
        } else {
          list.push(Number(obj.split(')')[0]))
        }
      })
      item.vertices = list
    }
    return item
  }

  PolygonMaker(data: AnyObj) {
    let polygonMarker: AnyObj = {}
    const floor = dgmap.getFloor(data.level)
    if (!floor) return
    const points: any[] = data.vertices
    const coords: AnyObj[] = []
    points.forEach((_, index) => {
      if (index < points.length / 2) {
        const obj: AnyObj = {}
        obj.x = Math.abs(points[index * 2])
        obj.y = points[index * 2 + 1]
        coords.push(obj)
      }
    })
    polygonMarker = new fengmap.FMPolygonMarker({
      // 设置颜色
      color: variables.yellowColor,
      // 设置透明度
      opacity: 0.7,
      // 设置边框线的宽度
      borderWidth: 0,
      borderColor: variables.yellowColor,
      // 设置高度
      height: 2,
      points: coords
    })
    polygonMarker.addTo(floor)
    this.createpoly = window.setInterval(() => {
      this.index++
      const alpha = this.index % 2 === 0 ? 0.7 : 0.9
      if (Object.keys(polygonMarker).length > 0) {
        polygonMarker.opacity = alpha
      }
    }, 800)
  }
}
