<script>
import ECharts from '@/components/common/Echarts/echart'
import { getDuration, timeType } from '@/utils'
import { variables } from '@/libs/theme'
export default {
  data() {
    return {
      option: {}
    }
  },
  props: {
    indicator: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    realValue: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  activated() {
    this.update()
  },
  methods: {
    update() {
      const option = {
        color: [[variables.yellowColor], [variables.colorPrimary]],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'rect', //图例形状
          data: ['本人数据', '平均数据'],
          bottom: 10,
          right: 10,
          itemWidth: 6, // 图例标记的图形宽度。[ default: 25 ]
          itemHeight: 6, // 图例标记的图形高度。[ default: 14 ]
          itemGap: 11, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
          textStyle: {
            fontSize: 12,
            color: variables.textColorRegular
          },
          selectedMode: false,
          selectorLabel: {
            show: true
          }
        },
        tooltip: {
          trigger: 'item',
          backgroundColor: [variables.backgroundColorPopper],
          textStyle: {
            color: [variables.textColorRegular]
          },
          padding: 10,
          lineHeight: 20,
          position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            const obj = { top: 80 }
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5
            return obj
          },
          extraCssText: `box-shadow: 0px 0px 10px 0px ${variables.backgroundColorPopper};border-radius:2px;`,
          formatter: params => {
            let resp
            if (params.name === '平均数据') {
              const name = this.type + params.name
              resp =
                '<p style="color:' +
                params.color +
                '">' +
                name +
                '</p><p>到访次数:' +
                params.value[0] +
                '次</p><p>平均停留时间:' +
                getDuration(params.value[1], timeType.TWO_SECTIONS) +
                '</p><p>到访区域数量:' +
                params.value[2] +
                '</p>'
            } else {
              resp =
                '<p style="color:' +
                params.color +
                '">' +
                params.name +
                '</p><p>到访次数:' +
                this.realValue[0] +
                '次</p><p>平均停留时间:' +
                getDuration(this.realValue[1], timeType.TWO_SECTIONS) +
                '</p><p>到访区域数量:' +
                this.realValue[2] +
                '</p>'
            }
            return resp
          }
        },
        radar: {
          name: {
            textStyle: {
              color: [variables.colorPrimary],
              fontSize: 12,
              fontFamily: 'SourceHanSansCN-Regular,SourceHanSansCN'
            }
          },
          nameGap: 5,
          center: ['50%', '50%'],
          radius: '60%',
          indicator: this.indicator,
          shape: 'circle',
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: [[variables.transparent], [variables.transparent]] // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            }
          },
          axisLine: {
            //指向外圈文本的分隔线样式
            lineStyle: {
              color: [variables.labelColor],
              width: 1
            }
          },
          splitLine: {
            lineStyle: {
              color: [variables.popBgColor], // 分隔线颜色
              width: 1 // 分隔线线宽
            }
          }
        },
        series: [
          {
            type: 'radar',
            symbol: 'circle',
            symbolSize: 7,
            lineStyle: {
              normal: {
                width: 2
              }
            },
            data: this.value
          }
        ]
      }
      this.option = option
    }
  },
  watch: {
    value() {
      this.update()
    }
  },
  components: {
    ECharts
  }
}
</script>
<template>
  <div>
    <e-charts :option="option" :auto_load="true"></e-charts>
    <slot />
  </div>
</template>
