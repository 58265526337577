





































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import _ from 'lodash'
import PersonVisit from '@/components/dashboard/PersonVisit/index.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import { ageGroup, parseGender, repoType, getAttr, getDuration, timeType } from '@/utils'
import addPersonal from '@/components/common/AddPersonnel/index.vue'
import CopyText from '@/components/common/CopyText.vue'
import { getParent } from '@/utils/getParents'
import RadarChart from '@/components/common/Echarts/RadarChart.vue'
import BarGraph from '@/components/common/Echarts/BarGraph.vue'
import RelationChart from '@/components/common/Echarts/RelationChart.vue'
import CalendarChart from '@/components/common/Echarts/CalendarChart.vue'
import photoDialog from '@/components/common/photoDialog.vue'
import TagsDialog from '@/components/common/TagsDialog.vue'
import DgMap from '@/components/dashboard/PersonDetail/fengmap.vue'
import SunburstChart from '@/components/common/Echarts/SunburstChart.vue'
import { themeName, variables, generate } from '@/libs/theme'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { backTop } from '@/utils/dom'
import store, { actionTypes } from '@/store'
import treeFind from '@/libs/operation-tree-node/treeFind'

const maps: AnyObj = {}

@Component({
  components: {
    FeatureImg,
    PersonVisit,
    addPersonal,
    CopyText,
    RadarChart,
    BarGraph,
    RelationChart,
    CalendarChart,
    photoDialog,
    TagsDialog,
    DgMap,
    SunburstChart,
    SecurityText
  }
})
export default class PersonDetail extends Vue {
  @Prop({ required: false, default: () => ({}) }) person: AnyObj
  @Prop({ required: false, default: false }) showDetail: boolean
  @Prop({ required: false }) screenCon: any
  @Prop({ required: false, default: '' }) project: string
  @Prop({ required: false, default: false }) isHideMap: boolean
  @Prop({ required: false, default: false }) drawerIndex: number
  private shadow9Color = variables.shadow9Color
  areaId: string | null = null
  currentAreaName = '-'
  info: AnyObj = {}
  selfImg = ''
  IntimacyList: any[] = []
  personId = ''
  treeList: any[] = []
  timeValue: any[] = []
  realValue: any[] = []
  frequency = 0
  mapLoad = true
  starLoad = false
  showMap = false
  indicator = [
    { text: '到访次数', max: 50 },
    { text: '平均停留时间', max: 500 },
    { text: '区域活跃度', max: 500 }
  ]
  selectList = [
    {
      name: '到访次数',
      index: 0
    },
    {
      name: '停留时间',
      index: 1
    }
  ]
  tabSelectIndex = 0
  isSunburst = true
  calendar: any[] = []
  yearList = ['2017', '2018', '2019']
  yearIndex = '2019'
  calendarMax = 0
  moreLoading = false
  noMore = false
  resultList: any[] = []
  finished = true
  groupID: number | null = null
  offset = 0
  accessCount = 0
  isShowTop = false
  calendarLoading = false
  noData = require('@/assets/images/dashboard/noData.png')
  tabLoading = false
  relationData: AnyObj = {}
  insights: any[] = []
  searchSelect: any = '1'
  startTime = ''
  endTime = ''
  searchTime = {
    '1': '最近一个月',
    '3': '最近三个月',
    '6': '最近半年',
    '12': '最近一年'
  }
  tagTypes = [
    {
      label: 'VIP',
      value: 'vip'
    },
    {
      label: '白名单',
      value: 'white'
    },
    {
      label: '黑名单',
      value: 'black'
    },
    {
      label: '陌生人',
      value: 'strange'
    }
  ]
  seachTag = 'vip'
  typeMap = {
    vip: 'VIP',
    white: '白名单',
    black: '黑名单',
    strange: '陌生人'
  }
  isShowMap = false
  mostFrequencyArea = '-'
  infoLoading = false
  avgLoading = false
  boxHeight = 0
  boxHeight2 = 0
  boxHeight3 = 0
  boxHeight4 = 0
  insightLoading = false
  areasLoading = false
  detailLoading = false
  startDate = null
  endDate = null
  areaIds: any[] = []
  areaName: any[] = []
  status = true
  areaList: any[] = []
  stayTimeVal: any[] = []
  areaTree: AnyObj | null = null
  errorloading = false
  showDetailDialog = false
  isOpenMap = false
  activeColor = variables.colorPrimary
  inActiveColor = variables.greyColor

  get personType() {
    return getAttr(this.info, 'tags[0].type', false)
  }

  get registered() {
    return this.info && this.info.tags
  }

  get timeType() {
    return timeType
  }

  get rootConfig() {
    return this.$store.state.config?.root
  }
  get activeAreaName() {
    return this.areaName.filter((item, index) => index < 3)
  }

  // 调用base api的临时方案，之后base api中areaId从header中取后，删掉这块的逻辑即可
  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get calendarColors() {
    const colors = generate(variables.colorPrimary)
      .filter((_, i) => i % 2 !== 0)
      .slice(0, 4)

    return themeName === 'light' ? colors : colors.reverse()
  }

  @Watch('searchSelect')
  watchSearchSelect(val: string) {
    this.endTime = moment(new Date()).endOf('day').toISOString()
    switch (val) {
      case '1':
        this.startTime = moment(new Date()).subtract(1, 'months').startOf('day').toISOString()
        break
      case '3':
        this.startTime = moment(new Date()).subtract(3, 'months').startOf('day').toISOString()
        break
      case '6':
        this.startTime = moment(new Date()).subtract(6, 'months').startOf('day').toISOString()
        break
      case '12':
        this.startTime = moment(new Date()).subtract(1, 'years').startOf('day').toISOString()
        break
      default:
        break
    }
    this.getAvgValue()
    this.getAreasInsights(this.personId)
    this.getMostFrequencyArea(this.personId)
  }

  @Watch('selfImg')
  watchSelfImg() {
    this.insightLoading = true
    this.getInsight() // 人员亲密度
  }

  async mounted() {
    this.showDetailDialog = this.showDetail
    this.$nextTick(() => {
      const { clientWidth: width } = this.$refs.boxLeft as Element
      const { clientWidth: width2 } = this.$refs.boxLeft2 as Element
      this.boxHeight = width * (269 / 469)
      this.boxHeight2 = width * (592 / 469)
      this.boxHeight3 = this.boxHeight + this.boxHeight2 + 10
      this.boxHeight4 = width2 * (670 / 509)
      if (this.drawerIndex === 1 || (this.drawerIndex === 2 && this.isHideMap)) {
        this.status = true
      } else {
        this.status = false
      }
    })

    this.personId = this.person.id
    this.initPage(this.person)
  }

  destroyed() {
    this.$message.closeAll()
    if (this.timer) clearTimeout(this.timer)
    if (Object.keys(maps).length > 0) {
      const index = this.isHideMap ? this.drawerIndex - 1 : this.drawerIndex
      if (maps[index]) {
        maps[index].dispose()
        const arr = window.maps.filter((i: any) => i.uuid !== maps[index].uuid)
        window.maps = [...arr]
        delete maps[index]
      }
    }
  }

  openMap() {
    this.isOpenMap = !this.isOpenMap
    if (this.isOpenMap && !this.showMap) {
      this.mapLoad = false
      this.starLoad = true
      this.isTimeout()
    } else {
      if (this.timer) clearTimeout(this.timer)
    }
  }
  async toPersonDeatil(val: AnyObj) {
    if (val.id && val.id !== this.personId) {
      this.$emit('toPersonDeatil', val)
    }
  }

  async initPage(val: AnyObj) {
    this.$message.closeAll()
    if (this.timer) clearTimeout(this.timer)
    this.treeList = []
    const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)

    if (areas) this.treeList = areas
    this.getPersonDetail('init') // 人员详细信息
    this.resultList = []
    this.tabLoading = true
    this.offset = 0
    this.$nextTick(() => {
      this.resetSearchVal(this.screenCon !== undefined && this.screenCon !== null && this.status)
      this.getTraceByPid() // 人次卡片
    })
    const myDate = new Date()
    const year = myDate.getFullYear()
    this.yearList = [(year - 2).toString(), (year - 1).toString(), year.toString()]
    this.yearIndex = year.toString()
    this.changeYear(year.toString()) // 年度到访图

    this.startTime = moment(new Date()).subtract(1, 'months').startOf('day').toISOString()
    this.endTime = moment(new Date()).endOf('day').toISOString()
    this.seachTag = val.tags && val.tags.length > 0 ? val.tags[0].type : 'strange'
    this.getAreasInsights(this.personId) // 人与区域之间的亲密关系
    this.getMostFrequencyArea(this.personId) // 最常访问地点
    this.getAvgValue() // 雷达图
  }

  async getAreaName() {
    if (this.status) {
      const allAreaId: AnyObj[] = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
      if (this.areaIds.length > 0) {
        this.areaIds.map(async item => {
          const area = treeFind(allAreaId, node => node.id === item)
          if (area) {
            this.areaName.push(area.name)
            this.areaName = Array.from(new Set(this.areaName))
          }
        })
      }
    }
  }

  async getMostFrequencyArea(id: string) {
    const opt = {
      params: {
        startTime: new Date(this.startTime).toISOString(),
        endTime: new Date(this.endTime).toISOString(),
        scopeId: this.root
      }
    }
    const res = await this.$api.base.mostFrequencyArea(id, opt)
    if (res.data && res.data.data) {
      const list = res.data.data
      let path = ''
      if (list.path && list.path.length > 0) {
        list.path.forEach((item: AnyObj) => {
          path = path + item.name + '-'
        })
      }
      path = path + list.name
      this.mostFrequencyArea = path
    } else {
      this.mostFrequencyArea = '-'
    }
  }

  async getAvgValue() {
    this.avgLoading = true
    const par1 = `${this.root}?personId=${this.personId}&&startTime=${this.startTime}&&endTime=${this.endTime}`
    const res1 = (await this.$api.base.getRadarData(par1)).data
    const par2 = `${this.root}?tagType=${this.seachTag}&&startTime=${this.startTime}&&endTime=${this.endTime}`
    const res2 = (await this.$api.base.getRadarData(par2)).data
    const resp = await Promise.all([res1, res2])
    this.avgLoading = false
    let avgStay = 300,
      areasCount = 300,
      times = 30
    this.indicator[0].max = 50
    this.indicator[1].max = 500
    this.indicator[2].max = 500
    const value2 = [0, 0, 0] // 每个维度的max为tagtype的平均数据的5/3，每个维度的本人数据如果大于tagtype的平均数据的8/3在雷达图的表现中则是tagtype的平均数据的8/3
    if (resp[1] && resp[1].data) {
      const val = resp[1].data
      const { avgStayTime, areaActivation, avgAccessTimes } = val
      if (avgStayTime && areaActivation && avgAccessTimes) {
        avgStay = avgStayTime
        areasCount = areaActivation
        times = avgAccessTimes
        value2[0] = times
        value2[1] = avgStay
        value2[2] = areasCount
        this.indicator[0].max = (times * 5) / 3
        this.indicator[1].max = (avgStay * 5) / 3
        this.indicator[2].max = (areasCount * 5) / 3
      }
    }
    const value1 = [0, 0, 0]
    this.realValue = [0, 0, 0]
    if (resp[0] && resp[0].data) {
      const val = resp[0].data
      if (val.avgAccessTimes > (times * 8) / 3) {
        value1[0] = (times * 8) / 3
      } else {
        value1[0] = val.avgAccessTimes
      }
      if (val.avgStayTime > (avgStay * 8) / 3) {
        value1[1] = (avgStay * 8) / 3
      } else {
        value1[1] = val.avgStayTime
      }
      if (val.areaActivation > (areasCount * 8) / 3) {
        value1[2] = (areasCount * 8) / 3
      } else {
        value1[2] = val.areaActivation
      }
      this.realValue[0] = val.avgAccessTimes
      this.realValue[1] = val.avgStayTime
      this.realValue[2] = val.areaActivation
    }
    this.frequency = Math.floor((this.realValue[0] / this.searchSelect) * 100) / 100
    this.timeValue = [
      {
        value: value1,
        name: '本人数据',
        areaStyle: {
          normal: {
            color: [variables.grey1Color]
          }
        }
      },
      {
        value: value2,
        name: '平均数据',
        areaStyle: {
          normal: {
            color: [variables.bg8Color]
          }
        }
      }
    ]
  }

  backToTop() {
    const detail = this.$refs.detailNode as HTMLElement
    backTop(detail)
  }

  async resetSearchVal(val: boolean) {
    this.areaList = []
    if (val) {
      this.areaIds = this.screenCon.areaIds
      this.startDate = this.screenCon.date[0]
      this.endDate = this.screenCon.date[1]
      if (!this.areaIds.includes(this.root)) {
        // 不包含scope就进行处理,
        this.areaIds.forEach(obj => {
          this.getSearchArea(this.treeList, obj)
        })
      }
    } else {
      this.areaIds = [this.root]
      this.startDate = null
      this.endDate = null
    }
    this.getAreaName()
  }

  switchChange(val: boolean) {
    this.tabLoading = true
    this.offset = 0
    this.noMore = false
    this.resultList = []
    this.resetSearchVal(val)
    this.getTraceByPid()
  }

  getSearchArea(tree: AnyObj[], id: string) {
    const getArea = (n: any[]) => {
      n.forEach(item => {
        this.areaList.push(item.id)
        if (item.children) {
          getArea(item.children)
        }
      })
    }

    tree.forEach(item => {
      if (item.id === id) {
        this.areaList.push(item.id)
        if (item.children) {
          getArea(item.children)
        }
        return
      }
      if (item.children) {
        this.getSearchArea(item.children, id)
      }
    })
  }

  async getAreasInsights(id: string) {
    this.areasLoading = true
    try {
      const opt = {
        params: {
          startTime: new Date(this.startTime).toISOString(),
          endTime: new Date(this.endTime).toISOString()
        }
      }
      const res = await this.$api.base.areasInsights(id, opt)
      if (res.data && res.data.data) {
        this.insights = res.data.data
        const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
        if (areas.length > 0) {
          this.areaTree = areas[0]
        }
        this.changeSelectTab({ index: 0 })
      }
      this.areasLoading = false
    } catch (err) {
      this.areasLoading = false
    }
  }

  async frequencyAreas(val: AnyObj[], type: string) {
    const obj = _.cloneDeep(this.areaTree)

    if (obj) {
      const resp = this.getAreaVal([obj], val, type)
      this.stayTimeVal = this.assignment(resp, type)
    }
  }

  assignment(val: AnyObj[], type: string) {
    val.forEach(obj => {
      if (obj.children && obj.children.length > 0) {
        this.assignment(obj.children, type)
      } else {
        if (type === 'times') {
          obj.value = obj.times
        }
        if (type === 'duration') {
          obj.value = obj.duration
        }
      }
    })
    return val
  }

  delKey(obj: AnyObj, ...args: any[]) {
    args.forEach(v => {
      delete obj[v]
    })
    return obj
  }

  getAreaVal(arr: AnyObj[], val: AnyObj[], type: string) {
    for (let i = 0, flag = true, len = arr.length; i < len; flag ? i++ : i) {
      if (arr[i] !== undefined) {
        const intimacy = val.filter(m => m.areaId === arr[i].id)
        let value = 0
        if (intimacy.length > 0) {
          if (type === 'times') {
            value = intimacy[0].times
          }
          if (type === 'duration') {
            value = intimacy[0].duration
          }
        }
        if (value !== 0 || arr[i].id === this.root) {
          if (intimacy.length > 0) {
            if (type === 'times') {
              arr[i].times = intimacy[0].times
              arr[i].frequency = intimacy[0].times + '次'
            }
            if (type === 'duration') {
              arr[i].duration = intimacy[0].duration
              arr[i].stayTime = this.getDuration(intimacy[0].duration, timeType.TWO_SECTIONS)
            }
          }

          this.delKey(
            arr[i],
            'description',
            'capacity',
            'mapId',
            'mapLabel',
            'createdAt',
            'updatedAt'
          )
          if (arr[i].children) {
            this.getAreaVal(arr[i].children, val, type)
          }
          flag = true
        } else {
          arr.splice(i, 1)
          flag = false
        }
      } else {
        flag = true
      }
    }
    return arr
  }

  changeSelectTab(val: AnyObj) {
    this.isSunburst = true
    this.tabSelectIndex = val.index
    if (this.insights.length > 0) {
      let num = null
      let type = 'times'
      if (this.tabSelectIndex === 0) {
        type = 'times'
        num = this.insights.filter(i => i.times !== 0)
      } else {
        type = 'duration'
        num = this.insights.filter(i => i.duration !== 0)
      }
      if (num.length > 0) {
        this.frequencyAreas(this.insights, type)
      } else {
        this.isSunburst = false
      }
    } else {
      this.isSunburst = false
    }
  }

  async getTraceByPid() {
    const params = {
      limit: 5,
      offset: this.offset,
      startTime: this.startDate,
      endTime: this.endDate,
      areaIds: this.areaIds,
      scopeId: this.root
    }
    let resp
    try {
      this.errorloading = false
      resp = await this.$api.v2.getPersonTraceApi(this.personId, params)
      if (resp.data && resp.data.code === 0) {
        this.finished = true
        if (this.offset + 5 >= resp.data.count) {
          this.noMore = true
        }
        this.resultList = [...this.resultList, ...resp.data.data]
      }
    } catch (e) {
      this.finished = true
      this.moreLoading = false
      this.tabLoading = false
      this.errorloading = true
      this.offset = this.offset - 5
    } finally {
      this.finished = true
      this.moreLoading = false
      this.tabLoading = false
    }
  }
  async getCurrentArea(config: AnyObj) {
    const resp = (await this.$api.v2.currentArea(this.personId, this.root)).data
    if (resp && resp.data) {
      const area = config.children.filter((i: AnyObj) => i.id === resp.data.id)
      if (resp.data.id === this.root) {
        this.currentAreaName = resp.data.name
        this.areaId = null
      } else {
        this.currentAreaName = this.getParents(resp.data.id)
        if (area.length > 0 && area[0].mapLabel !== null) {
          this.areaId = resp.data.id
        } else {
          this.areaId = null
        }
      }
      if (area.length > 0 && area[0].mapLabel !== null) {
        this.areaId = resp.data.id
      } else {
        this.areaId = null
      }
    } else {
      // 没有当前位置当前位置
      this.currentAreaName = '-'
    }
  }

  isTimeout() {
    if (this.message) this.message.close()
    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      if (!this.mapLoad) {
        this.mapLoad = true
        this.starLoad = false
        this.message = this.$message({
          type: 'error',
          message: '地图加载失败，点击加载按钮重新加载。',
          duration: 0,
          showClose: true,
          clickButton: '加载',
          onClick: async () => {
            this.mapLoad = false
            this.isTimeout()
            this.message.close()
          },
          createElement: this.$createElement
        })
      }
    }, 15000)
  }

  loadComplete(val: number, map: any) {
    if (val) {
      this.groupID = val
      maps[this.drawerIndex] = map
    } else {
      this.groupID = null
      this.areaId = null
    }
    this.showMap = true
    this.mapLoad = true
    this.backToTop()
  }
  boxScroll() {
    const box = this.$refs.detailNode as Element
    if (!box) return false
    const m = box.scrollHeight - box.scrollTop - box.clientHeight
    if (m > -2 && m < 2 && box.scrollTop !== 0 && this.finished) {
      this.finished = false
      if (!this.noMore && this.resultList.length > 0) {
        this.moreLoading = true
        setTimeout(() => {
          this.offset = this.offset + 5
          this.getTraceByPid()
        }, 1000)
      }
    }
    if (box.scrollTop > document.body.clientHeight) {
      this.isShowTop = true
    } else {
      this.isShowTop = false
    }
  }

  getVirtulData(year: string, value: AnyObj[]) {
    // 没有到访的日期补全值为-1
    year = year || '2017'
    const date = +echarts.number.parseDate(year + '-01-01')
    const end = +echarts.number.parseDate(+year + 1 + '-01-01')
    const dayTime = 3600 * 24 * 1000
    const data = []
    const valueTime = []
    value.forEach(obj => {
      valueTime.push(obj[0])
    })
    for (let time = date; time < end; time += dayTime) {
      const yearTime = echarts.format.formatTime('yyyy-MM-dd', time)
      let i = 0
      let flag = false
      value.forEach((obj, index) => {
        if (obj[0] === yearTime) {
          i = index
          flag = true
        }
      })
      if (flag) {
        data.push([echarts.format.formatTime('yyyy-MM-dd', time), value[i][1]])
      } else {
        data.push([echarts.format.formatTime('yyyy-MM-dd', time), -1])
      }
    }
    return data
  }

  async changeYear(item: string) {
    // 年度到访图
    this.calendarLoading = true
    this.yearIndex = item
    const month = item + '/' + 1 + '/' + 2
    const params = {
      date: new Date(month).toISOString()
    }
    try {
      const res = (await this.$api.base.getPersonCalendarAccess(this.personId, params)).data
      if (res && res.data) {
        this.calendarMax = res.data.max ? res.data.max : 2000
        res.data.data.map((obj: AnyObj) => {
          // 停留时间为0时设置为1
          if (obj[1] === 0) {
            obj[1] = 1
          }
        })
        this.accessCount = res.data.data.length
        this.calendar = this.getVirtulData(item, res.data.data)
      }
      this.calendarLoading = false
    } catch (err) {
      this.calendarLoading = false
    }
  }

  openAttr() {
    this.isOpen = !this.isOpen
  }

  handleRegister(obj: AnyObj) {
    ;(this.$refs.addPersonalNode as Vue).open('detailAdd', obj)
  }

  async clickEdit(id: string) {
    const res = await this.$api.base.getPersonSingle(id)
    ;(this.$refs.addPersonalNode as Vue).open('edit', res.data.data)
  }

  clickPhotoManage(row: AnyObj) {
    ;(this.$refs.photoDialogNode as Vue).open(row)
  }

  clickEditTgs(row: AnyObj) {
    ;(this.$refs.editTagsNode as Vue).open('editTags', row)
  }

  async clickDelete(id: string) {
    this.$confirm('人员一经删除无法恢复，是否确定删除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    })
      .then(async () => {
        const resp = await this.$api.base.getPersonsDelete(id)
        if (resp.status && resp.status === 204) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getPersonDetail('changeTag')
        }
      })
      .catch(() => {
        this.$message({
          message: '已取消删除',
          type: 'info'
        })
      })
  }

  getParents(areaId: string) {
    const list = getParent(this.treeList, areaId, this.root, true)
    const name = list.map(i => i.name).join('-')
    return name
  }

  formatData(value: AnyObj) {
    const detail: AnyObj[] = value.detail
    detail.map(obj => {
      obj.parents = getParent(this.treeList, obj.areaId, this.root)
    })
    const [...list] = detail
    let count = 0
    detail.forEach((item, index) => {
      if (item.parents.length > 0) {
        if (index === 0) {
          if (item.parents.length > 0) {
            list.splice(0, 0, item.parents)
            count++
          }
        } else {
          const arr = item.parents.filter((m: any) => {
            return !detail[index - 1].parents.includes(m)
          })
          if (arr.length > 0) {
            list.splice(index + count, 0, item.parents)
            count++
          }
        }
      }
    })
    value.detail = list
    return value
  }

  getDuration(seconds: number, type: timeType) {
    let res: string | undefined = ''
    if (seconds === undefined) {
      res = 0 + '秒'
    } else {
      res = getDuration(seconds, type)
    }
    return res
  }

  getAttr(obj: any, props: string, def: boolean) {
    return getAttr(obj, props, def)
  }

  parseGender(g: string) {
    return parseGender(g)
  }

  ageGroup(age: number) {
    return ageGroup(age)
  }

  repoType(type: string) {
    return repoType(type)
  }

  async getPersonDetail(type?: string) {
    this.infoLoading = true
    const p1 = await this.$api.base.getPersonInfo(this.personId)
    this.infoLoading = false
    if (p1.data && p1.data.data) {
      this.info = p1.data.data
      this.selfImg = this.info.imageUrl
      if (type === 'changeTag' || type === 'add') {
        this.seachTag =
          this.info.tags && this.info.tags.length > 0 ? this.info.tags[0].type : 'strange'
        this.getAvgValue() // 雷达图

        this.changeYear(this.yearIndex) // 年度到访图
      }

      if (type === 'init') {
        const config = await this.$store.state.config
        if (
          config &&
          config.children &&
          config.root.mapId !== null &&
          !/geo:(\d{6})$/.test(config.root.mapId)
        ) {
          this.isShowMap = true
        } else {
          this.isShowMap = false
        }
        if (this.info.status === 'active') {
          this.getCurrentArea(config) // 获取当前位置
        }
      }
    }
  }

  async getInsight() {
    const resp = await this.$api.base.getPersonInsight(this.personId)
    if (resp.data && resp.data.data) {
      this.IntimacyList = resp.data.data
      this.handleData()
    }
  }

  handleData() {
    const keyMap: Obj<string, string> = {
      score: 'value',
      imageUrl: 'symbol'
    }
    for (let i = 0; i < this.IntimacyList.length; i++) {
      const obj = this.IntimacyList[i]
      for (const key in obj) {
        const newKey = keyMap[key]
        if (newKey) {
          obj[newKey] = obj[key]
          delete obj[key]
        }
      }
    }
    const box = this.$refs.boxLeft as Element
    const width = box === undefined ? 300 : box.clientWidth
    const bodyWidth = document.body.clientWidth
    let size = 0
    let smallSize = 0
    if (bodyWidth > 1919) {
      size = 120
      smallSize = 50
    } else if (bodyWidth > 1441 && bodyWidth < 1919) {
      size = 105
      smallSize = 45
    } else {
      size = 90
      smallSize = 40
    }
    const item = {
      id: this.personId,
      symbol: this.info.imageUrl,
      fixed: true,
      x: width / 2 - 20,
      y: this.boxHeight4 - size - 20,
      symbolSize: size
    }
    if (this.info.tags && this.info.tags.length > 0) {
      Object.assign(item, { tags: this.info.tags, name: this.info.name })
    }
    this.IntimacyList.map(obj => {
      obj.symbolSize = smallSize + obj.value * 4
    })
    this.IntimacyList.unshift(item)
    const list: any[] = []
    this.IntimacyList.forEach((obj, index) => {
      const temp = {
        source: '',
        target: '',
        value: 0
      }
      temp.source = this.personId
      temp.target = obj.id || index
      temp.value = obj.value
      list.push(temp)
    })
    const relationData = {
      nodes: this.IntimacyList,
      links: list
    }
    this.relationData = relationData
  }

  complete() {
    this.insightLoading = false
  }
}
