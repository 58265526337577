































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { getDuration, timeType } from '@/utils'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import { getWidth } from '@/utils/dom'
import { getParent } from '@/utils/getParents'
import treeFind from '@/libs/operation-tree-node/treeFind'
import store, { actionTypes } from '@/store'
import AccessImage from '../../../views/SmartRealty/VisitorSearch/compenents/visitorDetail/accessImage.vue'
import { cloneDeep } from 'lodash'

@Component({ components: { FeatureImg, AccessImage } })
export default class PersonVisit extends Vue {
  @Prop({ required: true }) trip: AnyObj
  @Prop({ required: true }) selfImageUrl: AnyObj
  @Prop({ required: true }) areaList: AnyObj
  @Prop({ required: true }) isShowMoveLine: AnyObj
  @Prop({ required: true }) personVisitIndex: number

  partnerList: AnyObj[] = []
  left = false
  right = false
  clickIndex: number | null = null
  activeId = null
  leftLight = false
  rightLight = false
  showSee = false
  hoverIndex: number | null = null
  activeIndex = ''
  areaName = ''
  areaImageDetails: any[] = []
  loading = false
  hoverImageIndex: number | null = null
  imageDetail: any = {}
  showFlag = false

  get isSearchArea() {
    return this.areaList.length === 0
  }

  get isShowIcon() {
    return this.isShowMoveLine && this.isSearchArea
  }

  get timeType() {
    return timeType
  }

  @Watch('trip')
  watchTrip() {
    if (this.trip.trips) {
      const timeLine: any = this.$refs.timeLine
      const width = this.computedWidth()
      timeLine.style.width = width + 'px'
      timeLine.style.left = '0px'
      this.isLight()
    }
  }
  async getAreaName(areaId: string) {
    const area: any = treeFind(this.treeList, node => node.id === areaId)
    if (area) return area.name
    return null
  }
  async mounted() {
    document.addEventListener('click', this.hiddenNode)
    this.treeList = await store.dispatch(actionTypes.FETCH_BASE_AREAS)

    if (this.trip.trips) {
      const timeLine: any = this.$refs.timeLine
      const width = this.computedWidth()
      timeLine.style.width = width + 'px'
      this.isLight()
    }
    this.filterTrip()
    this.getPartner()
  }

  destroyed() {
    document.removeEventListener('click', this.hiddenNode)
  }
  hiddenNode(e: Event) {
    e.stopPropagation()
    if (this.clickIndex !== null) {
      const gv = 'partnerNode' + this.clickIndex
      const node = (this.$refs[gv] as Vue[])[0]
      const lineNode = this.$refs.lineNode as Vue

      if (node === undefined || lineNode === undefined) return
      if (!node.contains(e.target) && !lineNode.contains(e.target)) {
        this.clickIndex = null
        this.activeId = null
        this.rightLight = false
        this.leftLight = false
      }
    }
  }

  async clickAccessPoint(item: any, index: number, i: number) {
    const activeIndex = item.id + '-' + index + '-' + i
    if (!item.status && activeIndex !== this.activeIndex) {
      this.activeIndex = activeIndex
      this.areaName = item.name
      const captureIdsLength = item.captureIds?.length ?? 0
      const requireTimes = Math.ceil(captureIdsLength / 1000)
      let captureIdsArr = cloneDeep(item.captureIds)
      let areaImageUrlArr: any[] = []
      for (let i = 0; i < requireTimes; i++) {
        const newArray = captureIdsArr.slice(0, 1000)
        await this.getAreaImageUrl(newArray)
        captureIdsArr = captureIdsArr.slice(1000)
        areaImageUrlArr = areaImageUrlArr.concat(this.areaImageDetails)
      }
      this.areaImageDetails = areaImageUrlArr
    }
    const content = document.querySelector('.content-box3')?.querySelector('.modules')
    const personVisits: any = content?.querySelectorAll('.person-visit')
    const thirdPersonVisit = personVisits ? personVisits[this.personVisitIndex] : undefined
    const scrollArea: any = thirdPersonVisit
      ? thirdPersonVisit.querySelector('.area-image-wrap')?.querySelector('.detail-content')
      : undefined
    if (scrollArea) scrollArea.scrollLeft = 0
  }
  async getAreaImageUrl(captureIds: number) {
    this.loading = true
    const resp = await this.$api.v2.getCapturesSearch({
      ids: captureIds
    })
    this.loading = false
    if (resp.data && resp.data.data) this.areaImageDetails = resp.data.data
  }

  imageHover(index: number, type: boolean) {
    this.hoverImageIndex = type ? index : null
  }

  imageClick(obj: AnyObj) {
    this.showFlag = true
    const { areaId } = obj
    let areaName = '-'

    if (areaId) {
      const list = getParent(this.treeList, areaId, this.root, true)
      areaName = list.map(i => i.name).join('/')
    }
    this.imageDetail = { ...obj, areaName }
  }

  partnerHover(index: number, type: boolean) {
    if (type) {
      this.hoverIndex = index
    } else {
      this.hoverIndex = null
    }
  }

  partnerClick(index: number, item: AnyObj) {
    if (index !== this.clickIndex) {
      this.clickIndex = index
      this.activeId = item.id
      this.getLightBtn()
    }
  }

  personClick(item: AnyObj) {
    this.$emit('toPerson', item)
  }

  getLightBtn() {
    this.rightLight = false
    this.leftLight = false
    this.$nextTick(() => {
      const elements = this.$refs.timeNode
      if (elements !== undefined) {
        Array.prototype.forEach.call(elements, element => {
          const timeLine = this.$refs.timeLine as HTMLElement
          const left = timeLine.style.left
          const wrap = timeLine.parentElement
          const wrapWidth = Number(wrap?.style.width)
          if (element.offsetLeft > Math.abs(Number(left)) + wrapWidth) {
            this.rightLight = true
          }
          if (element.offsetLeft < Math.abs(Number(left))) {
            this.leftLight = true
          }
        })
      }
    })
  }

  activePartners(list: AnyObj[]) {
    const ids = list.map(i => i.id)
    return ids.includes(this.activeId)
  }

  async filterTrip() {
    let m = 0,
      n = 0
    let area: AnyObj = {}
    if (this.areaList.length > 0) {
      this.trip.trips.forEach((item: AnyObj, j: number) => {
        let index = 0
        let flag = false
        item.trace.map((obj: AnyObj, i: number) => {
          if ((obj.path && obj.path.length > 0) || i === item.trace.length - 1) {
            // trace最后一条，且最后一条有ptah做特殊处理
            if (i === item.trace.length - 1 && obj.path && obj.path.length > 0) {
              if (!this.areaList.includes(obj.id)) {
                obj.path[0].status = 'disabled'
              }
            }
            if (!flag && i !== 0) {
              const path = item.trace[index].path
              if (path.length > 0) path[0].status = 'disabled'
            }
            flag = false
            index = i
          }
          if (this.areaList.includes(obj.id)) {
            flag = true
            if (!area.id) {
              area = obj // 获取第一个区域点
              m = j
              n = i
              this.areaName = obj.name
            }
          } else {
            obj.status = 'disabled'
          }
        })
      })
      this.autoMove()
    } else {
      // 获取第一个区域点
      this.trip.trips.forEach((item: AnyObj, j: number) => {
        item.trace.forEach((obj: AnyObj, i: number) => {
          if (!area.id) {
            area = obj
            m = j
            n = i
            this.areaName = obj.name
          }
        })
      })
    }
    if (area && area.id) {
      this.activeIndex = area.id + '-' + m + '-' + n
      this.tempAreaImageDetails = []
      this.loading = true
      const captureIdsLength = area.captureIds?.length ?? 0
      const requireTimes = Math.ceil(captureIdsLength / 1000)
      let captureIdsArr = cloneDeep(area.captureIds)
      for (let i = 0; i < requireTimes; i++) {
        const newArray = captureIdsArr.slice(0, 1000)
        await this.getAreaImageUrl(newArray)
        captureIdsArr = captureIdsArr.slice(1000)
      }
      this.loading = false
    }
  }

  autoMove() {
    let m = 0,
      n = 0
    const trip = this.trip.trips
    for (let i = 0; i < trip.length; i++) {
      if (i > 0) n = n + trip[i - 1].trace.length
      for (let j = 0; j < trip[i].trace.length; j++) {
        if (trip[i].trace[j].path.length > 0) {
          n++
          if (trip[i].trace[j].path[0].status === undefined) {
            m = i
            n = j + n
            const rate = m * (140 + 60) + n * 134
            this.$nextTick(() => {
              if (rate > 134 * 2) {
                this.move(rate, 'autoMove')
              }
            })
            return
          }
        }
      }
    }
  }

  getPartner() {
    this.partnerList = []
    let list: AnyObj[] = []
    this.trip.trips.forEach((item: AnyObj) => {
      if (item.trace && item.trace.length > 0) {
        item.trace.forEach((obj: AnyObj) => {
          if (
            this.areaList.length === 0 ||
            (this.areaList.length > 0 && this.areaList.includes(obj.id))
          ) {
            list = [...list, ...obj.partners]
          }
        })
      }
    })
    list.forEach(m => {
      const ids = this.partnerList.map(i => i.id)
      if (!ids.includes(m.id)) {
        this.partnerList.push(m)
      }
    })
  }

  computedWidth() {
    const m = this.trip.trips.length
    let n = 0
    this.trip.trips.forEach((item: AnyObj) => {
      item.trace.forEach((obj: AnyObj) => {
        n = n + 1
        if (obj.path.length > 0) {
          n = n + 1
        }
      })
    })
    return m * (140 + 60) + n * 134
  }

  isLight() {
    const timeLine = this.$refs.timeLine as HTMLElement
    const width = getWidth(timeLine)
    const wrap = timeLine.parentElement
    const wrapWidth = getWidth(wrap)
    const canLeft = width - wrapWidth
    if (canLeft < 0) {
      this.left = false
      this.right = false
    } else {
      this.left = false
      this.right = true
    }
  }

  showMovingLine() {
    this.$store.state.dashboard.showDetailDialog = true
    this.$store.state.dashboard.navImg = this.selfImageUrl
    let list: AnyObj[] = []
    this.trip.trips.forEach((obj: AnyObj) => {
      list = [...list, ...obj.trace]
    })
    list.map(item => {
      return (item.areaId = item.id)
    })
    this.$store.state.dashboard.trip = list
  }

  getDuration(seconds: number, type: any, opt: boolean) {
    return getDuration(seconds, type, opt)
  }

  move(rate: number, type: string) {
    const timeLine = this.$refs.timeLine as HTMLElement
    const left = timeLine.offsetLeft
    const width = getWidth(timeLine)
    const wrap = timeLine.parentElement
    const wrapWidth = getWidth(wrap)
    const canLeft = width - wrapWidth
    if (canLeft < 0) return // 不能移动
    let moved
    if (type === 'autoMove') {
      moved = -rate
    } else {
      moved = left + rate * wrapWidth
    }
    if (moved >= 0) {
      moved = 0 // 点击左侧按钮直到不能移动的时候，此时左侧按钮不亮
      this.left = false
      this.right = true
    } else if (-moved >= canLeft) {
      // 点击右侧按钮直到不能移动的时候，此时右侧按钮不亮
      moved = -canLeft
      this.right = false
      this.left = true
    } else {
      this.right = true
      this.left = true
    }
    if (Math.abs(Math.abs(left) - Math.abs(moved)) > 60) {
      timeLine.style.left = `${moved}px`
      timeLine.animate([{ left: `${left}px` }, { left: `${moved}px` }], 700)
    }

    setTimeout(() => {
      this.getLightBtn()
    }, 700)
  }
}
