<script>
import ECharts from '@/components/common/Echarts/echart'
import { getDuration, timeType } from '@/utils'
import { themeName, variables, generate } from '@/libs/theme'

export default {
  data() {
    const colors = generate(variables.colorPrimary)
      .filter((_, i) => i % 2 !== 0)
      .slice(0, 4)

    return {
      option: {},
      colorBox: [
        variables.backgroundColorBase,
        ...(themeName === 'light' ? colors : colors.reverse())
      ]
    }
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    parentId: {
      type: String,
      required: true
    },
    year: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      required: true
    }
  },
  activated() {
    this.update()
  },
  methods: {
    generatePieces(maxValue, colorBox) {
      const pieces = []
      let quotient = 1
      const temp = {}
      temp.lt = 1
      temp.label = '0'
      temp.color = colorBox[0]
      pieces.push(temp)
      if (maxValue && maxValue >= 4) {
        quotient = Math.floor(maxValue / 4)
        for (let i = 0; i < 5; i++) {
          if (i === 4) {
            const temp = {}
            temp.gte = quotient * i + 1
            //temp.lte = quotient * (i+1);
            temp.color = colorBox[4]
            pieces.push(temp)
          } else {
            const temp = {}
            temp.gte = quotient * i + 1
            temp.lte = quotient * (i + 1)
            temp.color = colorBox[i + 1]
            pieces.push(temp)
          }
        }
      }
      return pieces
    },
    formatTime(time) {
      const duration = this.$moment.duration(time, 'seconds')
      const hour = duration.get('hours')
      const minute = duration.get('minutes')
      const second = duration.get('seconds')
      return `${hour ? hour + '小时' : ''}${hour || minute ? minute + '分' : ''}${
        second && second > 0 ? second + '秒' : ''
      }`
    },
    update() {
      const option = {
        tooltip: {
          position: 'top',
          textStyle: { color: variables.textColorRegular },
          backgroundColor: variables.backgroundColorPopper,
          formatter: function (params) {
            let result = ''
            if (params.value[1] === -1) {
              result = '<p style="margin:0;padding-bottom:8px">未到访</p>'
            } else {
              result =
                '<p style="padding:0 8px 8px 8px;margin-bottom:10px;">到访时间：<span style="color:${variables.colorPrimary};">' +
                getDuration(params.value[1], timeType.TWO_SECTIONS) +
                '</span></p>'
            }
            const temp =
              `<div style="font-size:14px;color:${variables.textColorRegular};background:${variables.backgroundColorPopper};margin:-5px -5px -20px -5px;text-align:center;"><p style="border-bottom:1px solid ${variables.backgroundColorMenu};padding:8px;">` +
              params.value[0] +
              '</p>' +
              result +
              '</div>'
            return temp
          }
        },
        visualMap: {
          min: 0,
          max: this.max,
          orient: 'horizontal',
          right: 'center',
          show: false,
          pieces: this.generatePieces(this.max, this.colorBox)
        },
        calendar: [
          {
            range: this.year,
            cellSize: [20, 20],
            left: 100,
            right: 60,
            top: 'center',
            splitLine: {
              show: false
            },
            itemStyle: {
              normal: {
                borderWidth: 2,
                opacity: 0
              }
            },
            dayLabel: {
              firstDay: 1,
              nameMap: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
              margin: 22,
              color: [variables.textColorSecondary]
            },
            monthLabel: {
              show: true,
              nameMap: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月'
              ],
              margin: 20,
              color: [variables.textColorSecondary]
            },
            yearLabel: { show: false }
          }
        ],

        series: [
          {
            type: 'heatmap',
            name: '同身份到访占比',
            coordinateSystem: 'calendar',
            calendarIndex: 0,
            data: this.value
          }
        ]
      }
      this.option = option
    }
  },
  watch: {
    value() {
      this.update()
    }
  },
  components: {
    ECharts
  }
}
</script>
<template>
  <div>
    <e-charts :option="option" :auto_load="true" :parentId="parentId"></e-charts>
    <slot />
  </div>
</template>
