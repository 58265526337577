<script>
import ECharts from '@/components/common/Echarts/echart'
import { variables } from '@/libs/theme'

export default {
  data() {
    return {
      option: {},
      typeMap: {
        vip: 'VIP',
        white: '白名单',
        black: '黑名单',
        strange: '陌生人'
      },
      defaultImg: require('@/assets/images/dashboard/load-error-person.png')
    }
  },
  props: {
    value: {
      // type: Array,
      required: true
    },
    parentId: {
      type: String,
      required: true
    }
  },
  activated() {
    this.update()
  },
  methods: {
    async update() {
      const loadImg = src =>
        new Promise((resovle, reject) => {
          const img = new Image()
          img.src = src

          img.onload = () => resovle(img)
          img.onerror = error => reject(error)
        })

      const data = await Promise.all(
        this.value.nodes.map(async item => {
          let img

          try {
            img = await loadImg(item.symbol)
          } catch (e) {
            img = await loadImg(this.defaultImg)
          }

          const min = Math.min(img.height, img.width)
          const max = Math.max(img.height, img.width)
          const radius = (item.symbolSize * (min / max)) / 2

          return {
            ...item,
            symbol: 'image://' + img.src,
            symbolKeepAspect: true,
            symbolClip: `circle(${radius} at center)`
          }
        })
      )

      const bodyWidth = document.body.clientWidth
      let repulsion = 80
      let edgeLength = 480
      if (bodyWidth > 1919) {
        repulsion = 80
        edgeLength = 480
      } else if (bodyWidth > 1441 && bodyWidth < 1919) {
        repulsion = 60
        edgeLength = 400
      } else {
        repulsion = 50
        edgeLength = 320
      }
      const option = {
        tooltip: {
          backgroundColor: variables.backgroundColorPopper
        },
        left: 'center',
        top: 'middle',
        right: 'auto',
        bottom: 'auto',
        series: [
          {
            type: 'graph',
            layout: 'force',
            force: {
              repulsion: repulsion, //节点之间的斥力因子
              gravity: 0.07,
              edgeLength: [90, edgeLength], //边的两个节点之间的距离，这个距离也会受 repulsion。
              layoutAnimation: false // 因为力引导布局会在多次迭代后才会稳定，这个参数决定是否显示布局的迭代动画
            },
            draggable: false,
            animation: false,
            hoverAnimation: false, //是否开启鼠标 hover 节点的提示动画效果。
            label: {
              normal: {
                show: false
              }
            },
            tooltip: {
              position: 'top',
              textStyle: { color: variables.textColorRegular },
              formatter: params => {
                let temp = ''
                if (params.data.id) {
                  let result = ''
                  if (params.data.tags) {
                    result =
                      '<p style="margin:0;">' +
                      params.data.name +
                      `</p><p style="color:${variables.colorPrimary};margin:0;">` +
                      this.typeMap[params.data.tags[0].type] +
                      '</p>'
                  } else {
                    result = '<p style="margin:0;">陌生人</p>'
                  }
                  temp =
                    `<div style="font-size:13px;color:${variables.textColorRegular};background:${variables.backgroundColorPopper};text-align:center;padding:8px;margin:-5px;">` +
                    result +
                    '</div>'
                }
                return temp
              }
            },
            symbol: 'circle',
            symbolSize: value => value + 50,
            itemStyle: {
              color: [variables.colorPrimary]
            },
            emphasis: {
              label: {
                show: false
              },
              itemStyle: {
                shadowColor: [variables.colorPrimary],
                shadowBlur: 15
              }
            },
            data,
            links: this.value.links,
            lineStyle: {
              normal: {
                width: 2,
                color: [variables.colorPrimary],
                curveness: -0.1
              }
            }
          }
        ]
      }
      setTimeout(() => {
        this.option = option
        this.$emit('complete')
      }, 1000)
    },
    clickNode(val) {
      this.$emit('toPerson', val)
    }
  },
  watch: {
    value() {
      this.update()
    }
  },
  components: {
    ECharts
  }
}
</script>
<template>
  <div>
    <e-charts
      :option="option"
      :auto_load="true"
      :parentId="parentId"
      @clickNode="clickNode"
    ></e-charts>
    <slot />
  </div>
</template>
